import { Button, Form, Modal, Select, Slider, Switch } from 'antd';
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { contrastLevelOptions } from 'common/default-value';
import useBeautyOptions from 'hooks/useBeautyOptions';
import useIsMobile from 'hooks/useIsMobile';
import styles from './styles.module.scss';
import { settingBeautyFilter } from 'api/profile';

interface IProps {
  open: boolean;
  toggle: () => void;
}

export default function SettingVideoFilterModal({ open, toggle }: IProps) {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const { beautyOptions, handleBeautyOptionsChange, handleEnableFilter, handleDisableFilter } = useBeautyOptions();
  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (beautyOptions) {
      form.setFieldsValue({
        beautyEffect: !!beautyOptions?.beautyEffect,
        contrastLevel: beautyOptions?.contrastLevel,
        smoothnessLevel: beautyOptions?.smoothnessLevel,
        whiteLevel: beautyOptions?.whiteLevel,
        rednessLevel: beautyOptions?.rednessLevel,
        sharpnessLevel: beautyOptions?.sharpnessLevel,
      });
    }
  }, [beautyOptions]);

  const handleSubmit = async (payload: any) => {
    setIsLoading(true);
    try {
      if (payload) {
        payload?.beautyEffect ? await handleEnableFilter() : await handleDisableFilter();
        await handleBeautyOptionsChange(payload);
        await settingBeautyFilter(payload);
      }
      toggle();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title="フィルター設定"
      visible={open}
      centered
      onCancel={toggle}
      footer={null}
      className={classNames(styles.settingVideoFilterModal, {
        [styles.inMobile]: isMobile,
      })}
    >
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item name="beautyEffect" label="フィルター設定" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="contrastLevel" label="コントラスト">
          <Select>
            {contrastLevelOptions.map((option) => (
              <Select.Option value={option} key={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="smoothnessLevel" label="スムージー">
          <Slider min={0} max={1} step={0.01} />
        </Form.Item>
        <Form.Item name="whiteLevel" label="白さ">
          <Slider min={0} max={1} step={0.01} />
        </Form.Item>
        <Form.Item name="rednessLevel" label="赤さ">
          <Slider min={0} max={1} step={0.01} />
        </Form.Item>
        <Form.Item name="sharpnessLevel" label="シャープネス">
          <Slider min={0} max={1} step={0.01} />
        </Form.Item>
        <div className={styles.btnSubmit}>
          <Button htmlType="submit" type="primary" disabled={isLoading}>
            設定する
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
