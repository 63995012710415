import { useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal, notification, Switch } from 'antd';
import Hamburger from 'hamburger-react';
import classNames from 'classnames';
import useCurrentLiveStream from 'hooks/useCurrentLiveStream';
import { LiveStreamMediaStatus, LiveStreamStatus, TYPE_CHAT } from 'common';
import {
  startLiveStream,
  auctionLiveStream,
  stopLiveStream,
  changeModeLiveStream,
  changeModeMediaLiveStream,
} from 'api/liveStream';
import { alertPopup, confirmPopup, convertPtToEn, formatNumber, handleErrorMessage } from 'helper';
import agoraManager from 'helper/agoraManager';
import { useHistory, useLocation } from 'react-router';
import logo from 'assets/images/logo.png';
import messageCountIcon from 'assets/images/messageCountIcon.svg';
import iconPoint from 'assets/images/iconPoint.svg';
import privateViewIcon from 'assets/images/privateViewIcon.svg';
import publicViewIcon from 'assets/images/publicViewIcon.svg';
import viewerCount from 'assets/images/viewerCount.svg';
import userDefault from 'assets/images/userDefault.svg';
import revenueIcon from 'assets/images/revenueIcon.svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import socket from 'helper/socket';
import usePointRevenue from 'hooks/usePointRevenue';
import { useMutation, useQueryClient } from 'react-query';
import useIsMobile from 'hooks/useIsMobile';
import useProfile from 'hooks/useProfile';
import MenuMobile from './MenuMobile';
import useFreeTimer from 'hooks/useFreeTimer';
import useViewerList from 'hooks/useViewerList';
import useLiveStreamStatistic from 'hooks/useLiveStreamStatistic';
import { SwiperSlide, Swiper } from 'swiper/react';
import moment from 'moment';
import useResource from 'hooks/useResource';
import StreamTimer from 'components/StreamTimer';
import useTypeChat from 'hooks/useTypeChat';
import useTotalMsg from 'hooks/useTotalMsg';
import SettingVideoFilterModal from 'components/Modal/SettingVideoFilterModal';
import useBeautyOptions from 'hooks/useBeautyOptions';
import Marquee from 'react-fast-marquee';
import FreeTimerCountDown from 'components/FreeTimerCountDown';
import { DeviceType } from 'utils/constant/enum';
import useShowMsgUnRead from 'hooks/useShowMsgUnRead';

export default function PageHeader() {
  const [openViewerList, setOpenViewerList] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisbleModal] = useState(false);
  const [enableVideoStream, setEnableVideoStream] = useState<LiveStreamMediaStatus>(
    LiveStreamMediaStatus.ALL_VIDEO_AUDIO
  );
  const { currentLiveStream } = useCurrentLiveStream();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const showMenuRouter = ['/'];
  const { t } = useTranslation();
  const { clearPointRevenue } = usePointRevenue();
  const [form] = Form.useForm();
  const isChangeToFree = useRef(false);
  const isChangeToPremium = useRef(false);
  const { isMobile, isOpenMenuMobile, setIsOpenMenuMobile } = useIsMobile();
  const [statusModeSwitch, setStatusModeSwitch] = useState(currentLiveStream?.status === LiveStreamStatus.FREE);
  const { showFreeTimer, setShowFreeTimer } = useFreeTimer();
  const profile = useProfile();
  const { pointRevenue } = usePointRevenue();
  const { viewerList } = useViewerList(currentLiveStream?.id);
  const { liveStreamStatistic } = useLiveStreamStatistic(currentLiveStream?.id);
  const resource: any = useResource();
  const { typeChat, setTypeChatMobile } = useTypeChat();
  const { totalMsg } = useTotalMsg(currentLiveStream?.id);
  const [openSettingVideoModal, setOpenSettingVideoModal] = useState(false);
  const { beautyOptions } = useBeautyOptions();
  const { showMsgUnread, setShowMsgUnread } = useShowMsgUnRead();

  const liveStreamStatus = useMemo(() => {
    if (!currentLiveStream)
      return {
        status: t('live.statusOffline'),
        guide: t('live.guideDuringBreak'),
      };
    if (currentLiveStream?.status === LiveStreamStatus.FREE)
      return {
        status:
          currentLiveStream?.mediaMode?.mode === LiveStreamMediaStatus.ONLY_AUDIO
            ? t('live.statusAudioFree')
            : t('live.statusFree'),
        guide: t('live.guideFree'),
      };
    if (currentLiveStream?.status === LiveStreamStatus.CHARGE)
      return {
        status:
          currentLiveStream?.mediaMode?.mode === LiveStreamMediaStatus.ONLY_AUDIO
            ? t('live.statusAudioCharge')
            : t('live.statusCharge'),
        guide: t('live.guideCharge'),
      };
    if (currentLiveStream?.status === LiveStreamStatus.PREMIUM)
      return {
        status:
          currentLiveStream?.mediaMode?.mode === LiveStreamMediaStatus.ONLY_AUDIO
            ? t('live.statusAudioCharge')
            : t('live.statusPremiumLivestream'),
        guide: t('live.guidePremium'),
      };
    if (currentLiveStream?.status === LiveStreamStatus.AUCTION)
      return {
        status: t('live.statusAuction'),
        guide: t('live.guideAuction'),
      };
    if (currentLiveStream?.status === LiveStreamStatus.SECRET_CHAT)
      return {
        status: t('live.statusSecretChat'),
        guide: t('live.guideSecretChat'),
      };
    if (currentLiveStream?.status === LiveStreamStatus.TWO_SHOT)
      return {
        status: t('live.statusTwoShot'),
        guide: t('live.guideTwoShot'),
      };
    return { status: '', guide: '' };
  }, [currentLiveStream, t]);

  const { isLoading, mutate: changeMode } = useMutation((params: any) => changeModeLiveStream(params), {
    onSuccess: () => {
      form.resetFields();
      if (isChangeToPremium.current) {
        alertPopup({
          title: t('live.changeModeToPremium'),
        });
        setStatusModeSwitch(!statusModeSwitch);
        return;
      }
      if (isChangeToFree?.current) {
        alertPopup({
          title: t('live.changeModeToFree'),
        });
        setVisbleModal(false);
      } else {
        alertPopup({
          title: t('live.changeModeToCharge'),
        });
      }
      setStatusModeSwitch(!statusModeSwitch);
    },
    onError: (err) => {
      handleErrorMessage(err);
    },
  });

  const changeModeMediaAction = useMutation(changeModeMediaLiveStream);

  useEffect(() => {
    const handleLiveStreamUpdated = ({ liveStream }: any) => {
      queryClient.setQueryData('currentLiveStream', liveStream);
      if (!liveStream) agoraManager.stop();
    };
    socket.on('liveStreamUpdated', handleLiveStreamUpdated);
    return () => {
      socket.off('liveStreamUpdated', handleLiveStreamUpdated);
    };
  }, [queryClient]);

  const navigate = (path: string) => {
    if (currentLiveStream && location.pathname === '/') {
      window.open(path);
    } else {
      history.push(path);
    }
  };

  const handleStartLiveStream = async (disableAudio?: boolean, isPremium?: boolean) => {
    const titleLiveStreamInput = document.querySelector('#titleLiveStream') as any;
    const titleLiveStream = titleLiveStreamInput?.value || '';
    if (!titleLiveStream) {
      message.destroy();
      message.error(t('live.pleaseEnterTitleLiveStream'));
      titleLiveStreamInput?.focus();
      return;
    }
    confirmPopup({
      title: isPremium
        ? '月額配信を開始しますか？'
        : disableAudio
        ? '音声配信を開始しますか？'
        : t('live.modalStartLiveStream'),
      onOk: async () => {
        try {
          setLoading(true);
          const agoraClient = await agoraManager.getClient();
          const [audioTrack, videoTrack] = await agoraManager.getAudioAndVideoTrack(beautyOptions);
          socket.emit('startLiveStream'); // broadcast to other tab, other browser
          clearPointRevenue();
          const {
            data: { appId, token, channel, uid },
          } = await startLiveStream({
            title: titleLiveStream,
            deviceType: isMobile ? DeviceType.MOBILE : DeviceType.DESKTOP,
            isPremium,
          });
          await agoraClient?.join(appId, channel, token, uid);
          await agoraClient?.publish([audioTrack, videoTrack]);
          videoTrack.play('stream_container', { fit: 'cover', mirror: true });
          if (disableAudio) {
            await agoraManager.disableVideoTrack();
            changeModeMediaAction.mutate(
              {
                mode: LiveStreamMediaStatus.ONLY_AUDIO,
              },
              {
                onSuccess: (data) => {
                  queryClient.invalidateQueries('currentLiveStream');
                },
              }
            );
          }
        } catch (e) {
          handleErrorMessage(e);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleStopLiveStream = async () => {
    confirmPopup({
      title: t('live.modalStopLiveStream'),
      onOk: async () => {
        try {
          await stopLiveStream();
          setLoading(true);
          await agoraManager.stop();
          const titleLiveStreamInput = document.querySelector('#titleLiveStream') as any;
          if (titleLiveStreamInput?.value) {
            titleLiveStreamInput.value = '';
          }
          setShowFreeTimer({
            visible: false,
            count: 0,
          });
        } catch (e) {
          handleErrorMessage(e);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleAuctionLiveStream = async () => {
    confirmPopup({
      title: t('live.modalAuctionLiveStream'),
      onOk: async () => {
        try {
          setLoading(true);
          await auctionLiveStream();
        } catch (e) {
          handleErrorMessage(e);
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleChangeModeLiveStream = async (values: any) => {
    changeMode({
      mode: LiveStreamStatus.FREE,
      duration: Number(values?.duration),
    });
    isChangeToFree.current = true;
    isChangeToPremium.current = false;
  };

  const handleOpenModalChangeMode = async () => {
    setVisbleModal(true);
  };

  const handleChangeChargeLive = async () => {
    isChangeToFree.current = false;
    isChangeToPremium.current = false;

    changeMode({
      mode: LiveStreamStatus.CHARGE,
    });
  };

  const handleSwitchChangeMode = async (isFree?: boolean) => {
    isChangeToPremium.current = false;
    if (isFree) {
      setVisbleModal(true);
    } else {
      isChangeToFree.current = false;
      changeMode({
        mode: LiveStreamStatus.CHARGE,
      });
    }
  };

  const handleChangeTypeChat = () => {
    if (showMsgUnread) {
      setShowMsgUnread(false);
    }
    if (typeChat === TYPE_CHAT.PRIVATE) {
      setTypeChatMobile(TYPE_CHAT.PUBLIC);
    } else {
      setTypeChatMobile(TYPE_CHAT.PRIVATE);
    }
  };

  const handleGetTotalMsg = () => {
    if (typeChat === TYPE_CHAT.PUBLIC) {
      return totalMsg?.public;
    }
    if (typeChat === TYPE_CHAT.PRIVATE) {
      return totalMsg?.private || 0;
    }
    return 0;
  };

  const handleChangeModeStreamMedia = async () => {
    const statusMedia = await agoraManager?.getStatusOfVideoTract();
    confirmPopup({
      title: !statusMedia
        ? '音声配信を終了しビデオ配信に切り替えますか？'
        : ' ビデオ配信を終了し音声配信に切り替えますか？',
      onOk: async () => {
        await agoraManager.disableVideoTrack();
        setEnableVideoStream(statusMedia ? LiveStreamMediaStatus.ALL_VIDEO_AUDIO : LiveStreamMediaStatus.ONLY_AUDIO);

        changeModeMediaAction.mutate(
          {
            mode: !statusMedia ? LiveStreamMediaStatus.ALL_VIDEO_AUDIO : LiveStreamMediaStatus.ONLY_AUDIO,
          },
          {
            onSuccess: (data) => {
              queryClient.invalidateQueries('currentLiveStream');
            },
          }
        );
      },
    });
  };

  const handleSettingFilter = () => {
    setOpenSettingVideoModal(true);
  };

  const handleChangeModeStreamPremium = async () => {
    isChangeToPremium.current = true;

    const isLivePremium = currentLiveStream?.status === LiveStreamStatus.PREMIUM;
    if (!currentLiveStream) {
      handleStartLiveStream(false, true);
      return;
    }
    confirmPopup({
      title: !isLivePremium
        ? '通常アーカイブ動画から月額アーカイブ動画に切り替えますか？'
        : ' 月額アーカイブ動画から通常アーカイブ動画に切り替えますか？',
      onOk: async () => {
        changeMode(
          {
            mode: LiveStreamStatus.PREMIUM,
          },
          {
            onSuccess: (data) => {
              queryClient.invalidateQueries('currentLiveStream');
            },
          }
        );
      },
    });
  };

  if (isMobile && !!currentLiveStream && window.location.pathname === '/') {
    return (
      <div className={styles.headerLiveStreamMobile}>
        {' '}
        <Swiper>
          <SwiperSlide>
            <div className={styles.containerTop} id="containerTop">
              <div className={styles.infoContainer}>
                <img src={profile?.avatar} className={styles.avatar} />
                <div className={styles.info}>
                  <div className="max-1-line"> {profile?.fullName} </div>
                  <div className="max-1-line">{currentLiveStream?.title} </div>
                </div>
              </div>
              <div className={styles.actionContainer}>
                {/* {!!currentLiveStream && (
                  <Button
                    onClick={handleChangeModeStreamMedia}
                    className={classNames({
                      buttonDefault: true,
                      buttonGreen: true,
                    })}
                    disabled={changeModeMediaAction.isLoading}
                  >
                    {agoraManager.getStatusOfVideoTract() ? '音声' : 'ビデオ'}
                  </Button>
                )} */}
                <Button
                  onClick={handleSettingFilter}
                  className={classNames({
                    buttonDefault: true,
                    buttonGreen: true,
                  })}
                >
                  美顔
                </Button>
                {([LiveStreamStatus.CHARGE, LiveStreamStatus.FREE].includes(currentLiveStream?.status) ||
                  !currentLiveStream) && (
                  <Button
                    onClick={handleChangeModeStreamPremium}
                    className={classNames({
                      buttonDefault: true,
                      buttonPremium: true,
                      buttonDisabled:
                        loading ||
                        [LiveStreamStatus.AUCTION, LiveStreamStatus.TWO_SHOT].includes(currentLiveStream?.status),
                    })}
                    disabled={
                      loading ||
                      [LiveStreamStatus.AUCTION, LiveStreamStatus.TWO_SHOT].includes(currentLiveStream?.status)
                    }
                  >
                    プレミアム
                  </Button>
                )}
                {[LiveStreamStatus.FREE, LiveStreamStatus.PREMIUM].includes(currentLiveStream?.status) && (
                  <Button
                    onClick={() => handleSwitchChangeMode()}
                    className={classNames({
                      buttonDefault: true,
                      buttonPurple: true,
                      buttonDisabled:
                        loading ||
                        [LiveStreamStatus.AUCTION, LiveStreamStatus.TWO_SHOT].includes(currentLiveStream?.status),
                    })}
                    disabled={
                      loading ||
                      [LiveStreamStatus.AUCTION, LiveStreamStatus.TWO_SHOT].includes(currentLiveStream?.status)
                    }
                  >
                    有料
                  </Button>
                )}
                {[LiveStreamStatus.CHARGE, LiveStreamStatus.PREMIUM].includes(currentLiveStream?.status) && (
                  <Button
                    onClick={() => handleSwitchChangeMode(true)}
                    className={classNames({
                      buttonDefault: true,
                      buttonPrimary: true,
                      buttonDisabled:
                        loading ||
                        [LiveStreamStatus.AUCTION, LiveStreamStatus.TWO_SHOT].includes(currentLiveStream?.status),
                    })}
                    disabled={
                      loading ||
                      [LiveStreamStatus.AUCTION, LiveStreamStatus.TWO_SHOT].includes(currentLiveStream?.status)
                    }
                  >
                    {/* {statusModeSwitch ? '有料' : '無料'} */}
                    無料
                  </Button>
                )}
                <Button
                  onClick={handleStopLiveStream}
                  className={classNames({
                    buttonDefault: true,
                    buttonYellow: true,
                    buttonDisabled: loading,
                  })}
                >
                  終了
                </Button>
                {/* <Button
                  onClick={handleAuctionLiveStream}
                  className={classNames(styles.btnAuction, {
                    buttonDefault: true,
                    buttomMobile: true,
                    buttonYellow: true,
                    buttonDisabled: loading || currentLiveStream?.status !== LiveStreamStatus.CHARGE,
                  })}
                >
                  オークション
                </Button> */}
              </div>
              <Marquee direction="left" className={styles.titleMobile}>
                <div className={styles.titleContent}>{currentLiveStream?.title}</div>
              </Marquee>
              <div className={styles.liveStreamStatus}>{liveStreamStatus?.status}</div>
              <div className={styles.streamerTime}>
                <StreamTimer startAt={currentLiveStream?.createdDate} />
              </div>
              <div
                className={classNames(styles.statusChat, {
                  [styles.privateStatus]: typeChat === TYPE_CHAT.PRIVATE,
                })}
              >
                {typeChat === TYPE_CHAT.PRIVATE ? '秘密のメッセージ' : 'オープンチャット'}
              </div>
            </div>
            <div className={classNames(styles.containerLeft, { [styles.viewerList]: openViewerList })}>
              {openViewerList ? (
                <div>
                  <div className={styles.closeIcon}>
                    <CloseOutlined onClick={() => setOpenViewerList(false)} />
                  </div>
                  {!viewerList?.length && <p className="text-center">{t('live.txtNoViewer')}</p>}
                  <div className={styles.listViewer}>
                    {viewerList?.map((viewer: any) => (
                      <div key={viewer.id} className={styles.itemView}>
                        <div className={styles.info}>
                          <img src={viewer.avatar || userDefault} alt="" />
                          <span>{viewer.nickname}</span>
                        </div>
                        <div className={styles.date}>
                          <span>
                            {moment(viewer?.joinedTime).format(isMobile ? 'HH時mm分' : 'YYYY年M月DD日 HH時mm分')}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  {/* <div className={styles.item}>
                    <img src={viewerCount} alt="view-count" />
                    <span>{liveStreamStatistic?.peeperCount}人</span>
                  </div> */}
                  <div className={styles.item} onClick={() => setOpenViewerList(true)}>
                    <img src={messageCountIcon} alt="view-count" />
                    <span>{liveStreamStatistic?.chatterCount}人</span>
                  </div>
                  <div className={styles.item}>
                    <img src={iconPoint} alt="view-count" />
                    <span>{formatNumber(pointRevenue?.totalPoint)}pt</span>
                  </div>
                  <div className={styles.item}>
                    <img src={revenueIcon} alt="view-count" />
                    <span>
                      {formatNumber(convertPtToEn(pointRevenue?.totalPoint, resource?.data?.POINT_EXCHANGE_RATE))}円
                    </span>
                  </div>
                  <div className={styles.item} onClick={handleChangeTypeChat}>
                    {!!showMsgUnread && <div className={styles.dotRed}></div>}
                    <img src={typeChat === TYPE_CHAT.PUBLIC ? publicViewIcon : privateViewIcon} alt="view-count" />
                    <span>{handleGetTotalMsg()}件</span>
                  </div>
                </>
              )}
            </div>
            {!!showFreeTimer?.visible && (
              <FreeTimerCountDown
                duration={showFreeTimer?.count}
                onEnd={() => setShowFreeTimer({ visible: false, count: 0 })}
              />
            )}
            <Modal title="" centered visible={visibleModal} footer={null} closable={false}>
              <Form layout="vertical" onFinish={handleChangeModeLiveStream} form={form}>
                <p>{t('common.changeModeTitle1')}</p>
                <Form.Item
                  name="duration"
                  label={t('common.duration')}
                  rules={[
                    { whitespace: true, message: t('validate.canNotBeEmpty') },
                    { required: true, message: t('validate.fieldIsRequired') },
                    () => ({
                      validator(_, value) {
                        if (value && !isNaN(value) && value <= 0) {
                          return Promise.reject(new Error(t('validate.minInput1')));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <div className={styles.btnContainer}>
                  <Button
                    htmlType="button"
                    className={classNames(styles.btnClose)}
                    onClick={() => setVisbleModal(false)}
                    disabled={isLoading}
                  >
                    {t('btn.cancel')}
                  </Button>
                  <Button htmlType="submit" className={classNames(styles.btnSubmit)} disabled={isLoading}>
                    {t('btn.submit')}
                  </Button>
                </div>
              </Form>
            </Modal>{' '}
          </SwiperSlide>
          <SwiperSlide></SwiperSlide>
        </Swiper>
        {openSettingVideoModal && (
          <SettingVideoFilterModal
            open={openSettingVideoModal}
            toggle={() => setOpenSettingVideoModal(!openSettingVideoModal)}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <div className={styles.headerWrapper}>
        {isMobile ? (
          <div className={styles.hamburgerWrapper}>
            <Hamburger
              toggled={isOpenMenuMobile}
              toggle={(value: any) => setIsOpenMenuMobile(value)}
              color={'#e50055'}
            />
            <div className={styles.buttonCherryLiveWrapper}>
              <img src={logo} onClick={() => (window.location.pathname !== '/' ? navigate('/') : {})} alt="" />
            </div>
            {/* <Switch
              checkedChildren="有料"
              unCheckedChildren="無料"
              defaultChecked
              onChange={handleSwitchChangeMode}
              checked={statusModeSwitch}
            /> */}
          </div>
        ) : (
          <></>
        )}

        <Modal title="" centered visible={visibleModal} footer={null} closable={false}>
          <Form layout="vertical" onFinish={handleChangeModeLiveStream} form={form}>
            <p>{t('common.changeModeTitle')}</p>
            <Form.Item
              name="duration"
              label={t('common.duration')}
              rules={[
                { whitespace: true, message: t('validate.canNotBeEmpty') },
                { required: true, message: t('validate.fieldIsRequired') },
                () => ({
                  validator(_, value) {
                    if (value && !isNaN(value) && value <= 0) {
                      return Promise.reject(new Error(t('validate.minInput1')));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input type="number" />
            </Form.Item>
            <div className={styles.btnContainer}>
              <Button
                htmlType="button"
                className={classNames(styles.btnClose)}
                onClick={() => setVisbleModal(false)}
                disabled={isLoading}
              >
                {t('btn.cancel')}
              </Button>
              <Button htmlType="submit" className={classNames(styles.btnSubmit)} disabled={isLoading}>
                {t('btn.submit')}
              </Button>
            </div>
          </Form>
        </Modal>

        {!isMobile && (
          <>
            <Link to="/" className={classNames(styles.itemMenu, 'cursor')}>
              <div className={styles.logo}>
                <img src={logo} alt="" className="img-scaledown" />
              </div>
            </Link>{' '}
            {showMenuRouter.includes(history.location.pathname) && (
              <div className={classNames(styles.itemMenuButton, { [styles.inMobile]: isMobile })}>
                {((!!currentLiveStream && currentLiveStream.status !== LiveStreamStatus.PREMIUM) ||
                  !currentLiveStream) && (
                  <Button
                    onClick={handleChangeModeStreamPremium}
                    className={classNames({
                      buttonDefault: true,
                      buttonPremium: true,
                      buttonDisabled: loading,
                    })}
                  >
                    {t('live.liveStreamPremium')}
                  </Button>
                )}
                {/* {!!currentLiveStream ? (
                  <Button
                    onClick={handleChangeModeStreamMedia}
                    className={classNames({
                      buttonDefault: true,
                      buttonGreen: true,
                    })}
                    disabled={changeModeMediaAction.isLoading}
                  >
                    {agoraManager.getStatusOfVideoTract() ? '音声配信' : 'ビデオ配信'}
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleStartLiveStream(true)}
                    className={classNames({
                      buttonDefault: true,
                      buttonGreen: true,
                    })}
                    disabled={changeModeMediaAction.isLoading}
                  >
                    音声配信
                  </Button>
                )} */}
                <Button
                  onClick={handleSettingFilter}
                  className={classNames({
                    buttonDefault: true,
                    buttonGreen: true,
                    buttonSettingBeauty: true,
                  })}
                >
                  {t('live.settingBeauty')}
                </Button>
                {!!currentLiveStream &&
                  (currentLiveStream?.status === LiveStreamStatus.FREE ||
                    currentLiveStream.status === LiveStreamStatus.PREMIUM) && (
                    <Button
                      onClick={handleChangeChargeLive}
                      className={classNames({
                        buttonDefault: true,
                        buttonRed: true,
                        buttonDisabled:
                          loading ||
                          [LiveStreamStatus.AUCTION, LiveStreamStatus.TWO_SHOT].includes(currentLiveStream?.status),
                      })}
                    >
                      {t('live.notFreeLiveStream')}
                    </Button>
                  )}

                {!!currentLiveStream &&
                  (currentLiveStream?.status === LiveStreamStatus.CHARGE ||
                    currentLiveStream.status === LiveStreamStatus.PREMIUM) && (
                    <Button
                      onClick={handleOpenModalChangeMode}
                      className={classNames({
                        buttonDefault: true,
                        buttonPrimary: true,

                        buttonDisabled:
                          loading ||
                          [LiveStreamStatus.AUCTION, LiveStreamStatus.TWO_SHOT].includes(currentLiveStream?.status),
                      })}
                    >
                      {t('live.freeLiveStream')}
                    </Button>
                  )}

                {!currentLiveStream && (
                  <Button
                    onClick={() => handleStartLiveStream()}
                    className={classNames({
                      buttonDefault: true,
                      buttonYellow: true,
                      buttonDisabled:
                        loading ||
                        [LiveStreamStatus.AUCTION, LiveStreamStatus.TWO_SHOT].includes(currentLiveStream?.status),
                    })}
                  >
                    {t('live.startLiveStream')}
                  </Button>
                )}
                {!!currentLiveStream && (
                  <Button
                    onClick={handleStopLiveStream}
                    className={classNames({
                      buttonDefault: true,
                      buttonYellow: true,
                      buttonDisabled: loading,
                    })}
                  >
                    {t('live.stopLiveStream')}
                  </Button>
                )}

                {/* <Button
                  onClick={handleAuctionLiveStream}
                  className={classNames({
                    buttonDefault: true,
                    buttonYellow: true,
                    buttonHide: !currentLiveStream,
                    buttonDisabled: loading || currentLiveStream?.status !== LiveStreamStatus.CHARGE,
                  })}
                >
                  {t('live.startAuctionLiveStream')}
                </Button> */}
              </div>
            )}
            <div className={`d-flex flex-wrap align-items-center`}>
              <div className={`${styles.itemMenu} cursor`} onClick={() => navigate('/video-history')}>
                {t('header.deliveryHistory')}
              </div>
              <div className={`${styles.itemMenu} cursor`} onClick={() => navigate('/profile')}>
                {t('header.myPage')}
              </div>
            </div>
          </>
        )}
      </div>

      {isMobile && (
        <>
          {showMenuRouter.includes(history.location.pathname) && (
            <div className={classNames(styles.itemMenuButton, { [styles.inMobile]: isMobile })}>
              {!currentLiveStream && (
                <>
                  {((!!currentLiveStream && currentLiveStream.status !== LiveStreamStatus.PREMIUM) ||
                    !currentLiveStream) && (
                    <Button
                      onClick={handleChangeModeStreamPremium}
                      className={classNames({
                        buttonDefault: true,
                        buttonPremium: true,
                        buttomMobile: true,
                        buttonDisabled: loading,
                      })}
                    >
                      {t('live.liveStreamPremium')}
                    </Button>
                  )}
                  <Button
                    onClick={() => handleStartLiveStream()}
                    className={classNames({
                      buttonDefault: true,
                      buttonYellow: true,
                      buttomMobile: true,
                      buttonDisabled:
                        loading ||
                        [LiveStreamStatus.AUCTION, LiveStreamStatus.TWO_SHOT].includes(currentLiveStream?.status),
                    })}
                  >
                    {t('live.startLiveStream')}
                  </Button>
                  <Button
                    onClick={handleSettingFilter}
                    className={classNames({
                      buttonDefault: true,
                      buttonLightRed: true,
                      buttonSettingBeauty: true,
                      buttomMobile: true,
                    })}
                  >
                    美顔設定
                  </Button>
                  {/* <Button
                    onClick={handleStopLiveStream}
                    className={classNames({
                      buttonDefault: true,
                      buttonYellow: true,
                      buttomMobile: true,
                      buttonDisabled: !currentLiveStream,
                    })}
                  >
                    {t('live.stopLiveStream')}
                  </Button> */}
                </>
              )}
              {!!currentLiveStream && (
                <Button
                  onClick={handleStopLiveStream}
                  className={classNames({
                    buttonDefault: true,
                    buttonYellow: true,
                    buttomMobile: true,
                    buttonDisabled: loading,
                  })}
                >
                  {t('live.stopLiveStream')}
                </Button>
              )}
              {/* {!!currentLiveStream && (
                <Button
                  onClick={handleAuctionLiveStream}
                  className={classNames({
                    buttonDefault: true,
                    buttomMobile: true,
                    buttonYellow: true,
                    buttonDisabled: loading || currentLiveStream?.status !== LiveStreamStatus.CHARGE,
                  })}
                >
                  {t('live.startAuctionLiveStream')}
                </Button>
              )} */}
            </div>
          )}
        </>
      )}
      {isMobile && <MenuMobile />}
      {openSettingVideoModal && (
        <SettingVideoFilterModal
          open={openSettingVideoModal}
          toggle={() => setOpenSettingVideoModal(!openSettingVideoModal)}
        />
      )}
    </div>
  );
}
