import { sendGet, sendPost } from './axios';

export const getPrivateMail = (params: any) => sendPost('/idol/private-mail/new/list', params);
export const readPrivateMail = (id: any) => sendGet(`/idol/private-mail/read/${id}`);
export const replyPrivateMail = (id: number, params: any) => sendPost(`/idol/private-mail/reply/${id}`, params);
export const getDetailPrivateMail = (params: any) =>
  sendPost(`/idol/private-mail/detail/${params?.memberId}`, params?.params);
export const replyPrivateMailNew = (params: any) =>
  sendPost(`/idol/private-mail/reply/${params?.memberId}`, params?.data);
export const getTotalUnreadPrivateMail = () => sendGet('/idol/private-mail/total-unread');
