import { useCallback, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export default function useIsMobile() {
  const queryClient = useQueryClient();
  const { data: isMobile }: any = useQuery('is_mobile', () => (window.innerWidth <= 1024 ? true : false));
  const { data: isOpenMenuMobile }: any = useQuery('is_open_menu_mobile', () =>
    queryClient.getQueryData('is_open_menu_mobile')
  );
  const setIsOpenMenuMobile = useCallback((value: any) => queryClient.setQueryData('is_open_menu_mobile', value), [
    queryClient,
  ]);

  useEffect(() => {
    const listenerScreen = () => {
      queryClient.setQueryData('is_mobile', window.innerWidth <= 1024 ? true : false);
    };
    window.addEventListener('resize', listenerScreen);
    return () => {
      window.removeEventListener('resize', listenerScreen);
    };
  }, [queryClient]);

  return { isMobile, isOpenMenuMobile, setIsOpenMenuMobile };
}
