import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styles from './styles.module.scss';

interface Props {
  startAt: string;
}

export default function StreamTimer({ startAt }: Props) {
  const [time, setTime] = useState('');
  useEffect(() => {
    if (!startAt) return;
    const interval = setInterval(() => {
      let seconds = moment().diff(startAt, 'second');
      if (seconds < 0) {
        seconds = 0;
      }
      const hour = `${Math.floor(seconds / 3600)}`.padStart(2, '0');
      const minute = `${Math.floor(seconds / 60) % 60}`.padStart(2, '0');
      const second = `${Math.floor(seconds % 60)}`.padStart(2, '0');
      setTime(`${hour}:${minute}:${second}`);
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, [startAt]);
  if (!startAt) return <span />;
  return <span className={styles.timer}>{time}</span>;
}
