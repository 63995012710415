import { TYPE_CHAT } from 'common';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export default function useTypeChat() {
  const queryClient = useQueryClient();
  const { data: typeChat }: any = useQuery(
    'typeChatMobile',
    () => queryClient.getQueryData('typeChatMobile') || TYPE_CHAT.PUBLIC
  );

  const setTypeChatMobile = useCallback(
    (type: TYPE_CHAT) => {
      queryClient.setQueryData('typeChatMobile', type);
    },
    [queryClient]
  );

  return { typeChat, setTypeChatMobile };
}
