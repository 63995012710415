import AgoraRTC, { IAgoraRTCClient, IMicrophoneAudioTrack, ICameraVideoTrack } from 'agora-rtc-sdk-ng';
import { createLiveStreamToken } from 'api/liveStream';
import BeautyExtension, { IBeautyProcessor, BeautyEffectOptions } from 'agora-extension-beauty-effect';
import { IBeautyOptionsData } from 'common/interface';
import { transformBeautyOptionsData } from 'helper';

class AgoraManager {
  private client?: IAgoraRTCClient;
  private audioTrack?: IMicrophoneAudioTrack;
  private videoTrack?: ICameraVideoTrack;
  private processor?: IBeautyProcessor;
  public enabledBeauty = false;

  async getClient() {
    if (this.client) return this.client;
    const client = AgoraRTC.createClient({
      mode: 'live',
      codec: 'vp8',
    });
    const extension = new BeautyExtension();
    AgoraRTC.registerExtensions([extension]);

    const processor = extension.createProcessor();

    this.client = client;
    this.processor = processor;

    await client.setClientRole('host');
    client.on('token-privilege-will-expire', async () => {
      try {
        const {
          data: { token },
        } = await createLiveStreamToken();
        client.renewToken(token);
      } catch {}
    });
    client.on('user-published', async (user, mediaType) => {
      // Subscribe to a remote user.
      await client.subscribe(user, mediaType);
      // If the subscribed track is video.
      if (mediaType === 'video') {
        // Get `RemoteVideoTrack` in the `user` object.
        const remoteVideoTrack = user.videoTrack;
        // Dynamically create a container in the form of a DIV element for playing the remote video track.
        // Specify the ID of the DIV container. You can use the `uid` of the remote user.

        // Play the remote video track.
        // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
        const playerContainer: any = document.getElementById('stream_container');
        remoteVideoTrack?.play(playerContainer, { fit: 'cover', mirror: true });

        // Or just pass the ID of the DIV container.
        // remoteVideoTrack.play(playerContainer.id);
      }

      // If the subscribed track is audio.
      if (mediaType === 'audio') {
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = user.audioTrack;
        // Play the audio track. No need to pass any DOM element.
        remoteAudioTrack?.play();
      }
    });

    return client;
  }
  async getAudioTrack() {
    if (this.audioTrack) return this.audioTrack;
    const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    this.audioTrack = audioTrack;
    return audioTrack;
  }
  async getVideoTrack() {
    if (this.videoTrack) return this.videoTrack;
    const videoTrack = await AgoraRTC.createCameraVideoTrack();
    this.videoTrack = videoTrack;
    return videoTrack;
  }

  async disableVideoTrack() {
    if (this.videoTrack?.muted) {
      return this.videoTrack?.setMuted(false);
    } else {
      return this.videoTrack?.setMuted(true);
    }
  }

  getStatusOfVideoTract() {
    return !this.videoTrack?.muted;
  }
  async reloadCameraBeautyEffect() {
    if (this.processor && this.videoTrack) {
      this.videoTrack.pipe(this.processor).pipe(this.videoTrack.processorDestination);
    }
  }

  async getAudioAndVideoTrack(beautyOptions?: IBeautyOptionsData) {
    const audioTrack = await this.getAudioTrack();
    const videoTrack = await this.getVideoTrack();

    if (beautyOptions?.beautyEffect) {
      await this.enableBeauty();
    }
    if (beautyOptions) {
      await this.changeProcessorOptions(transformBeautyOptionsData(beautyOptions));
    }

    return [audioTrack, videoTrack];
  }

  async enableBeauty() {
    if (this.processor) {
      await this.processor.enable();
      if (this.videoTrack) {
        await this.reloadCameraBeautyEffect();
      }
      this.enabledBeauty = true;
    }
  }
  async disableBeauty() {
    if (this.processor) {
      await this.processor.disable();
      if (this.videoTrack) {
        await this.reloadCameraBeautyEffect();
      }
      this.enabledBeauty = false;
    }
  }
  async changeProcessorOptions(options: BeautyEffectOptions) {
    if (options && this.processor) {
      await this.processor.setOptions(options);
      if (this.videoTrack) {
        await this.reloadCameraBeautyEffect();
      }
    }
  }

  async stop() {
    this.client?.leave();
    this.client = undefined;
    this.audioTrack?.close();
    this.videoTrack?.close();
    this.audioTrack = undefined;
    this.videoTrack = undefined;
    this.processor?.disable();
    this.processor = undefined;
    this.enabledBeauty = false;
  }
}
const agoraManager = new AgoraManager();
export const agoraManager2 = new AgoraManager();

export default agoraManager;
