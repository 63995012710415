import { ConfigProvider } from 'antd';
import 'antd/dist/antd.css';
import jaJP from 'antd/lib/locale/ja_JP';
import 'helper/i18n';
import 'helper/socket';
import 'moment/locale/ja';
import React from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ReactDOM from 'react-dom';
import 'styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-quill/dist/quill.snow.css';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={jaJP}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
