import React from 'react';
import styles from './styles.module.scss';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import useCurrentLiveStream from 'hooks/useCurrentLiveStream';
import useIsMobile from 'hooks/useIsMobile';

export default function Footer() {
  const { t } = useTranslation();
  const { currentLiveStream } = useCurrentLiveStream();
  const { isMobile } = useIsMobile();

  if (!!currentLiveStream && isMobile) return null;
  return (
    <React.Fragment>
      <div className={styles.footer}>
        <div className={styles.subFooterWrapper}>
          <div className={styles.coppyRight}>{t('footer.copyRight')}</div>
        </div>
      </div>
    </React.Fragment>
  );
}
