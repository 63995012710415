import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';
import useIsMobile from 'hooks/useIsMobile';
interface Props {
  duration: number;
  onEnd: () => void;
}
function FreeTimerCountDown({ duration, onEnd }: Props) {
  const [counter, setCounter] = useState(duration || 0);
  const { isMobile } = useIsMobile();
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter) => {
        const newCounter = counter - 1;
        if (newCounter <= 0) onEnd();
        return newCounter;
      });
    }, 1000);
    return () => {
      clearInterval(interval);
      onEnd();
    };
  }, []);
  return <div className={classNames(styles.timer, { [styles.inMobile]: isMobile })}>{counter}</div>;
}

export default React.memo(FreeTimerCountDown);
