import { COMMON_STATUS } from 'common';
import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export default function useShowMsgUnRead() {
  const queryClient = useQueryClient();
  const { data: showMsgUnread }: any = useQuery('show_msg_unread', () => queryClient.getQueryData('show_msg_unread'));
  const setShowMsgUnread = useCallback((value: any) => queryClient.setQueryData('show_msg_unread', value), [
    queryClient,
  ]);

  return { showMsgUnread: showMsgUnread || !!COMMON_STATUS.INACTIVE, setShowMsgUnread };
}
