import { history, queryClient } from 'App';
import configs from 'config';
import { alertPopup, showEmoji } from 'helper';
import Cookies from 'js-cookie';
import { io } from 'socket.io-client';
import i18n from 'i18next';
import agoraManager from './agoraManager';
import { loadProfile } from 'api/profile';
import _ from 'lodash';
import { EventSocket, TYPE_CHAT } from 'common';

const socket = io(configs.API_DOMAIN, { transports: ['websocket', 'polling'] });

socket.on('connect', () => {
  authenticateSocket();
});

socket.on('startLiveStream', () => {
  // Redirect to profile page if other tab start live stream
  if (window.location.pathname === '/') {
    history.push('/profile');
    alertPopup({ message: i18n.t('live.youLiveStreamingInAnotherDevice') });
  }
});

socket.on('twoShotMemberExited', () => {
  agoraManager.stop();
  if (window.location.pathname === '/') {
    alertPopup({ message: i18n.t('live.twoShotMemberExited') });
  }
});

socket.on('statistic', (data) => {
  queryClient.setQueryData(['liveStreamStatistic', data?.liveStreamId], data);
});

socket.on('partyChatMessage', (message) => {
  queryClient.setQueryData(['list_party_chat_message', message.liveStreamId], (data: any) => {
    if (!data) return data;
    const newData = { ...data };
    newData.pages = [...newData.pages, { data: [message] }];
    return newData;
  });
  const totalMsgData = queryClient.getQueryData(['total_msg', message?.liveStreamId]) as any;
  const typeChat = queryClient.getQueryData('typeChatMobile') as any;
  if (typeChat !== TYPE_CHAT.PUBLIC) {
    queryClient.setQueryData('show_msg_unread', true);
  }
  if (!totalMsgData?.public) {
    const listMessage = queryClient.getQueryData(['list_party_chat_message', message?.liveStreamId]) as any;
    if (Array.isArray(listMessage?.pages)) {
      let totalMsg = 0;
      listMessage?.pages?.forEach((page: any) => {
        if (page?.total) {
          totalMsg = page?.total;
          return false;
        }
      });
      queryClient.setQueryData(['total_msg', message.liveStreamId], (data: any) => {
        if (!data) return data;
        return { ...data, public: (totalMsg || 0) + 1, private: totalMsgData?.private };
      });
    }
  } else {
    queryClient.setQueryData(['total_msg', message.liveStreamId], (data: any) => {
      if (!data) return data;
      return { ...data, public: (totalMsgData?.public || 0) + 1, private: totalMsgData?.private };
    });
  }
});

socket.on('privateMessage', (message) => {
  queryClient.setQueryData(['list_private_chat_message', message.liveStreamId], (data: any) => {
    if (!data) return data;
    const newData = { ...data };
    newData.pages = [...newData.pages, { data: [message] }];
    return newData;
  });
  const totalMsgData = queryClient.getQueryData(['total_msg', message?.liveStreamId]) as any;
  const typeChat = queryClient.getQueryData('typeChatMobile') as any;
  if (typeChat !== TYPE_CHAT.PRIVATE) {
    queryClient.setQueryData('show_msg_unread', true);
  }
  if (!totalMsgData?.private) {
    const listMessage = queryClient.getQueryData(['list_private_chat_message', message?.liveStreamId]) as any;
    if (Array.isArray(listMessage?.pages)) {
      const totalMsg = listMessage?.pages?.reduce((page: any) => page?.length);
      queryClient.setQueryData(['total_msg', message.liveStreamId], (data: any) => {
        if (!data) return data;
        return { ...data, private: (totalMsg || 0) + 1, public: totalMsgData?.public };
      });
    }
  } else {
    queryClient.setQueryData(['total_msg', message.liveStreamId], (data: any) => {
      if (!data) return data;
      return { ...data, private: (totalMsgData?.private || 0) + 1, public: totalMsgData?.public };
    });
  }
});

socket.on('pointRevenue', (data) => {
  queryClient.setQueryData('pointRevenue', data);
});

socket.on('auctionData', ({ liveStream, data }: any) => {
  queryClient.setQueryData(['auctionData', liveStream?.id], data);
});

socket.on('stamp', (dataStamp) => {
  showEmoji(dataStamp.stamp, dataStamp.stampPrice);
  queryClient.setQueryData(['streamNotifications', dataStamp?.liveStreamId], (oldData: any) => {
    return [...(oldData || []), dataStamp];
  });
});
socket.on('viewerList', ({ liveStream, viewerList }) => {
  queryClient.setQueryData(['viewerList', liveStream?.id], viewerList);
});

export const authenticateSocket = async () => {
  if (Cookies.get('token')) {
    await loadProfile(); // refresh token before authen socket
    socket.emit('authenticate', { token: Cookies.get('token'), role: 'Idol' });
  }
};
socket.on('stopAuction', async ({ maxAuction }) => {
  try {
    let message = i18n.t('live.waitUser');
    if (!maxAuction) {
      await agoraManager.stop();
      const titleLiveStreamInput = document.querySelector('#titleLiveStream') as any;
      if (titleLiveStreamInput?.value) {
        titleLiveStreamInput.value = '';
      }
      message = i18n.t('live.stopAuctionModalMessageNoAuction');
    }
    alertPopup({
      title: i18n.t('live.stopAuctionModalTitle'),
      message,
    });
  } catch (error) {}
});

socket.on(EventSocket.New_Private_Msg, (message) => {
  // fix scroll to bottom
  queryClient.setQueryData('total-unread', {
    data: {
      total: Number(message?.count),
    },
  });
  if (history.location.pathname.includes('/mypage')) {
    queryClient.invalidateQueries('listMailReceived');
  }
  const listMessageWrapper = document.querySelector('#detailPrivateMail');
  listMessageWrapper?.scrollTo(0, 0);

  // end fix

  queryClient.setQueryData(['detailPrivateMail', `${message?.data?.senderId}`], (data: any) => {
    if (!data) return data;
    const newData = { ...data };
    newData.pages = [{ data: [message?.data] }, ...newData.pages];
    return newData;
  });
});

socket.on('streamStoppedByAdmin', () => {
  agoraManager.stop();
  alertPopup({ message: i18n.t('common.streamStoppedByAdmin') });
});

export default socket;
