import { useQuery, useQueryClient } from 'react-query';

export default function useLiveStreamStatistic(liveStreamId: number) {
  const queryClient = useQueryClient();
  const { data: liveStreamStatistic }: any = useQuery(
    ['liveStreamStatistic', liveStreamId],
    () => queryClient.getQueryData(['liveStreamStatistic', liveStreamId]) || { chatterCount: 0, peeperCount: 0 }
  );

  return { liveStreamStatistic };
}
