import configs from 'config';
import { message } from 'antd';
import { confirmAlert } from 'react-confirm-alert';
import i18next from 'i18next';
import {
  FormatTime,
  HIGH_GIFT_THRESHOLD,
  KeyboardKeyCode,
  ListLiveStreamVideoType,
  optionsNotification,
  OrderStatus,
  POINT_HISTORY_TYPE,
  STATUS,
  STATUS_MEMBER,
  VIDEO_STATUS,
} from 'common';
import _, { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { IBeautyOptionsData, IDeliveryAddress, IImage } from 'common/interface';
import { BeautyEffectOptions } from 'agora-extension-beauty-effect';

export const handleErrorMessage = (error: any) => {
  // Show error message
  message.destroy();
  message.error(getErrorMessage(error));
  if (configs.APP_ENV !== 'prod') {
    // tslint:disable-next-line: no-console
    console.log(error);
  }
};

export const getErrorMessage = (error: any) => {
  // handle special error
  if (error?.name === 'AgoraRTCException') {
    if (error?.code === 'PERMISSION_DENIED') {
      return i18next.t('live.deviceNotFound');
    }
  }
  return error?.response?.data?.errorMessage || error?.message || 'Something went wrong!';
};

export const getVideoTypeText = (type: number) => {
  if (type === ListLiveStreamVideoType.NORMAL) return i18next.t('common.partyChatVideo');
  if (type === ListLiveStreamVideoType.TWO_SHOT) return i18next.t('common.2ShotVideo');
};

interface IConfirmPopup {
  title?: string;
  message?: string;
  okText?: string;
  cancelText?: string;
  onOk?: () => any;
  onCancel?: () => any;
}
export const confirmPopup = ({
  title,
  message,
  okText = 'はい',
  cancelText = 'いいえ',
  onOk = () => {},
  onCancel = () => {},
}: IConfirmPopup) => {
  confirmAlert({
    title,
    message,
    buttons: [
      {
        label: cancelText,
        onClick: onCancel,
      },
      {
        label: okText,
        onClick: onOk,
      },
    ],
  });
};

export const alertPopup = ({ title, message, okText = 'はい', onOk = () => {} }: IConfirmPopup) => {
  confirmAlert({
    title,
    message,
    closeOnClickOutside: false,
    closeOnEscape: false,
    buttons: [
      {
        label: okText,
        onClick: onOk,
      },
    ],
  });
};

export const formatNumber = (number: any) => {
  if (!number) return '0';
  return String(number).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

export const convertPtToEn = (pt: number, rate: number) => {
  return Math.floor((pt || 0) * (rate || 1.1));
};

function getRndInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getNumberEmoji(price: number) {
  if (price < 2000) {
    return 1;
  }
  if (price > 7000) {
    return 6;
  }
  return Math.floor((price - 1000) / 1000);
}

export const showEmoji = (url: string, price: number) => {
  const emoji = document.createElement('img');
  const listEmoji: HTMLImageElement[] = [];
  let numberEmoji = getNumberEmoji(price);
  emoji.src = url;
  const { left, right } = document.getElementById('stream_container')?.getBoundingClientRect() || {};
  if (!(Number(left) + Number(right))) {
    return;
  }
  emoji.style.left = getRndInteger(Number(left), Number(right)) + 'px';
  emoji.classList.add('emoji');
  // kiểm tra quà giá trị cao
  if (Number(price) >= HIGH_GIFT_THRESHOLD) {
    let lastPosition = 0;
    // số emoji xuất hiện
    for (let i = 0; i < numberEmoji; i++) {
      const emoji2 = document.createElement('img');
      let randNumber = getRndInteger(Number(left), Number(right));
      while (randNumber > lastPosition - 15 && randNumber < lastPosition + 15) {
        randNumber = getRndInteger(Number(left), Number(right));
      }
      lastPosition = randNumber;
      emoji2.src = url;
      emoji2.style.left = randNumber + 'px';
      emoji2.classList.add('emoji2');
      if (numberEmoji === 5) {
        emoji2.classList.add('extraSizeEmoji1');
      }
      if (numberEmoji === 6) {
        emoji2.classList.add('extraSizeEmoji2');
      }
      listEmoji.push(emoji2);
    }
  } else {
    listEmoji.push(emoji);
  }
  emoji.onload = () => {
    // document.body.append(emoji);
    for (let i = 0; i < listEmoji.length; i++) {
      setTimeout(() => {
        document.body.append(listEmoji[i]);
      }, i * 200);
    }
    setTimeout(() => {
      // document.body.removeChild(emoji);
      listEmoji.forEach((el) => document.body.removeChild(el));
    }, 10000);
  };
};

export const requestFullscreen = (element: any) => {
  if (element?.requestFullscreen) {
    element.requestFullscreen();
  } else if (element?.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element?.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  } else if (element?.msRequestFullscreen) {
    element.msRequestFullscreen();
  }
};
export const exitFullscreen = () => {
  let document = window.document as any;
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
};

export const formatPriceToJpString = (price: number) => {
  if (!price) return '';
  return price.toLocaleString('ja-JP');
};

export const getBase64 = (img: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result as string));
    reader.readAsDataURL(img);
  });

export const getTextStatusVideo = (status: number) => {
  if (status === VIDEO_STATUS.DISABLE) {
    return i18next.t('video.videoDisable');
  }
  if (status === VIDEO_STATUS.ENABLE) {
    return i18next.t('video.videoEnable');
  }
  if (status === VIDEO_STATUS.STREAMING) {
    return i18next.t('video.videoStreaming');
  }
  if (status === VIDEO_STATUS.PROCESSING) {
    return i18next.t('video.videoProcessing');
  }
  if (status === VIDEO_STATUS.FAILED) {
    return i18next.t('video.videoFailed');
  }
};

export const getPointHistoryType = (type: number) => {
  if (type === POINT_HISTORY_TYPE.BUY) {
    return i18next.t('option.point_buy');
  }
  if (type === POINT_HISTORY_TYPE.REFUND) {
    return i18next.t('option.point_refund');
  }
  if (type === POINT_HISTORY_TYPE.PARTY_CHAT) {
    return i18next.t('option.point_party_chat');
  }
  if (type === POINT_HISTORY_TYPE.PARTY_PEEP) {
    return i18next.t('option.point_party_peep');
  }
  if (type === POINT_HISTORY_TYPE.PRIVATE_MESSAGE) {
    return i18next.t('option.point_private_message');
  }
  if (type === POINT_HISTORY_TYPE.PRIVATE_MAIL) {
    return i18next.t('option.point_private_mail');
  }
  if (type === POINT_HISTORY_TYPE.GIFT) {
    return i18next.t('option.point_gift');
  }
  if (type === POINT_HISTORY_TYPE.PRIVATE_REQUEST) {
    return i18next.t('option.point_private_request');
  }
  if (type === POINT_HISTORY_TYPE.AUCTION) {
    return i18next.t('option.point_auction');
  }
  if (type === POINT_HISTORY_TYPE.BUY_VIDEO) {
    return i18next.t('option.point_buy_video');
  }
  if (type === POINT_HISTORY_TYPE.EXPIRE) {
    return i18next.t('option.point_expire');
  }
  if (type === POINT_HISTORY_TYPE.ORDER) {
    return i18next.t('option.point_order');
  }
  if (type === POINT_HISTORY_TYPE.SUBSCRIPTION_MONTHLY) {
    return i18next.t('option.point_package_monthly');
  }
  if (type === POINT_HISTORY_TYPE.PREMIUM) {
    return i18next.t('option.premium');
  }
};

export const getTypeNofitication = (type: number) => {
  const notificationType = optionsNotification.find((el) => el.type === type);
  return notificationType?.label || '';
};

export const getTextStatusMember = (
  status: STATUS_MEMBER.ACTIVE | STATUS_MEMBER.BLOCK | STATUS_MEMBER.DELETE | STATUS_MEMBER.PENDING
) => {
  if (status === STATUS_MEMBER.ACTIVE) {
    return i18next.t('option.verified');
  }
  if (status === STATUS_MEMBER.BLOCK) {
    return i18next.t('option.block');
  }
  if (status === STATUS_MEMBER.PENDING) {
    return i18next.t('option.pending');
  }
  if (status === STATUS_MEMBER.DELETE) {
    return i18next.t('option.out_group');
  }
};

//Format text
export const trimText = (str?: string) => {
  if (!str) return '';
  return str.trim().replace(/\s+/gm, ' ');
};

export const handleKeyDownNumber = (e: any) => {
  const key = e.which || e.keyCode; // keyCode detection
  const value = parseInt(get(e, 'target.value', '').replaceAll('%', '') + '');
  if (key === 8 || key === 9 || key === 46) {
    // backspace, tab, delete
    return;
  }
  const ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false; // ctrl detection
  if (key === 86 && ctrl) {
    // Ctrl + V
    return;
  } else if (key === 67 && ctrl) {
    // Ctrl + C
    return;
  } else if (key === 65 && ctrl) {
    // Ctrl + A
    return;
  }

  if ((key === 96 || key === 48) && Number.isNaN(value)) {
    e.preventDefault();
    return;
  }
  if ((key >= 48 && key <= 57) || (key >= 96 && key <= 105)) {
    // 0-9 only
    return;
  }
  e.preventDefault();
};

//Table
export const getIndexTable = (pageIndex: number, pageSize: number, current: number) => {
  return (pageIndex - 1) * pageSize + current + 1;
};

export const formatListProductImage = (listImage: IImage[]) => {
  return listImage?.map((img) => {
    return { uid: uuidv4(), url: img?.url };
  });
};

export const getOrderStatusText = (status: OrderStatus) => {
  if (!status) {
    return '';
  }
  if (status === OrderStatus.PREPARING) {
    return i18next.t('orderStatus.preparing');
  }
  if (status === OrderStatus.IN_TRANSIT) {
    return i18next.t('orderStatus.inTransit');
  }
  if (status === OrderStatus.SUCCESS) {
    return i18next.t('orderStatus.success');
  }
  if (status === OrderStatus.FAILED) {
    return i18next.t('orderStatus.failed');
  }
};

export const convertTimeToLocal = (time?: string, format?: string) => {
  if (!time) return '';
  return moment(time)
    .format(format || FormatTime.DATE_TIME)
    .toString();
};

export const getDeliveryAddress = ({
  firstName = '',
  lastName = '',
  firstNameKatakana = '',
  lastNameKatakana = '',
  zipCode = '',
  province = '',
  district = '',
  home = '',
  phone = '',
}: IDeliveryAddress) => {
  const name = firstName + ' ' + lastName;
  const nameKatakana =
    firstNameKatakana || lastNameKatakana ? '（' + firstNameKatakana + ' ' + lastNameKatakana + '）' : '';
  const zipCodeRender = zipCode ? '〒' + zipCode : '';
  return [name + nameKatakana, zipCodeRender, province + district, home, phone].join('\n');
};

export const getProductStatusText = (productStatus: STATUS) => {
  if (productStatus === STATUS.ACTIVE) {
    return i18next.t('productStatus.onSale');
  }
  if (productStatus === STATUS.INACTIVE) {
    return i18next.t('productStatus.sold');
  }
  return '';
};

export const checkTimeAgo = (dateTime?: string) => {
  const diff = moment().diff(moment(dateTime), 'minutes');
  const diff_m = moment().diff(moment(dateTime), 'month');
  if (diff < 1) {
    return '数秒前';
  } else if (diff < 60) {
    return `${diff} 分前`;
  } else if (diff < 1440) {
    return `${Math.floor(diff / 60)} 時間前`;
  }
  if (diff_m < 12) {
    return `${diff_m} 月前`;
  } else {
    return `${Math.floor(diff_m / 12)} 年前`;
  }
};

export const convertDataQueryInfinite = (data: any, notReverse?: boolean) => {
  if (!data?.pages?.length) return [];
  return !notReverse
    ? _.reverse(
        data?.pages?.reduce((previousValue: any, currentValue: any) => previousValue.concat(currentValue.data), [])
      )
    : data?.pages?.reduce((previousValue: any, currentValue: any) => previousValue.concat(currentValue.data), []);
};

export const handleKeydownForwardSlash = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const key = e.which || e.keyCode;

  if (key === KeyboardKeyCode.FORWARD_SLASH) {
    e.preventDefault();
  }

  return;
};

export const handlePasteForwardSlash = (e: React.ClipboardEvent<HTMLInputElement>) => {
  const clipboardData = e.clipboardData;

  if (clipboardData.getData('Text').includes('/')) {
    e.preventDefault();
  }

  return;
};

export const transformBeautyOptionsData = (beautyOptions: IBeautyOptionsData) => {
  const beautyOptionsData: BeautyEffectOptions = {
    lighteningContrastLevel: beautyOptions?.contrastLevel,
    lighteningLevel: beautyOptions?.whiteLevel,
    smoothnessLevel: beautyOptions?.smoothnessLevel,
    sharpnessLevel: beautyOptions?.sharpnessLevel,
    rednessLevel: beautyOptions?.rednessLevel,
  };
  return beautyOptionsData;
};

export const assignDomain = (url: string) => {
  if (url?.startsWith('http')) return url;
  return `${configs.AWS_DOMAIN}/${url}`;
};
