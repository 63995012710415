import { useQuery, useQueryClient } from 'react-query';

export default function useTotalMsg(liveStreamId: number) {
  const queryClient = useQueryClient();
  const { data: totalMsg }: any = useQuery(
    ['total_msg', liveStreamId],
    () =>
      queryClient.getQueryData(['total_msg', liveStreamId]) || {
        private: 0,
        public: 0,
      }
  );
  return { totalMsg };
}
