import { IFilter } from './interface';

export const defaultFilter: IFilter = {
  pageIndex: 1,
  take: 10,
};

export const productCommentData = [
  {
    id: 1,
    email: 'youniginzu1@gmail.com',
    content: 'content1aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa',
    createdAt: '11/11/2011',
  },
];

export const contrastLevelOptions = [0, 1, 2];
export const beautyDefaultOptions = {
  contrastLevel: 1,
  smoothnessLevel: 0.5,
  whiteLevel: 0.6,
  rednessLevel: 0.1,
  sharpnessLevel: 0.3,
};
