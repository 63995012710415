import { sendGet, sendPost } from './axios';

// eslint-disable-next-line import/prefer-default-export
export const createLiveStreamToken = () => sendPost('/idol/live-stream/token');
export const getCurrentLiveStream = () => sendGet('/idol/live-stream/current').then((res) => res.data);
export const startLiveStream = (payload: any) => sendPost('/idol/live-stream/start', payload);
export const auctionLiveStream = () => sendPost('/idol/live-stream/auction');
export const stopLiveStream = () => sendPost('/idol/live-stream/stop');
export const blockMember = (payload: any) => sendPost('/idol/live-stream/block', payload);
export const getLiveStreamNotifications = (liveStreamId: number) =>
  sendGet(`/idol/live-stream/${liveStreamId}/notifications`).then((res) => res.data);
export const checkLiveStreaming = () => sendGet('/idol/live-stream/check-live-streaming').then((res) => res.data);
export const changeModeLiveStream = (payload: any) => sendPost('/idol/live-stream/change-mode', payload);
export const getRevenueLiveStream = () => sendGet('/idol/live-stream/get-revenue').then((res) => res.data);
export const changeModeMediaLiveStream = (payload: any) => sendPost('/idol/live-stream/change-mode-media', payload);
