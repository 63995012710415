import { getCurrentLiveStream } from 'api/liveStream';
import { loadProfile } from 'api/profile';
import Footer from 'components/Footer';
import PageHeader from 'components/PageHeader';
import { authenticateSocket } from 'helper/socket';
import Cookies from 'js-cookie';
import React, { lazy, Suspense, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Redirect, Route, Switch } from 'react-router-dom';
import styles from './styles.module.scss';
import { getListConfigs } from 'api/config';
import { IDOL_CONFIGS } from 'common';
import { alertPopup } from 'helper';
import { history } from 'App';

const Main = lazy(() => import('pages/Main'));
const Mypage = lazy(() => import('pages/Mypage/mail'));
const DetailPrivateMailNew = lazy(() => import('pages/Mypage/mail/DetailPrivateMail'));
const VideoHistory = lazy(() => import('pages/VideoHistory'));
const DetailPrivateMail = lazy(() => import('pages/Mypage/mail/Unanswered'));
const Gallery = lazy(() => import('pages/Mypage/Gallery'));
const Profile = lazy(() => import('pages/Mypage/profile/Profile'));
const IdolRevenue = lazy(() => import('pages/Mypage/IdolRevenue'));
const ChangePassword = lazy(() => import('pages/Mypage/ChangePassword'));
const BankConfig = lazy(() => import('pages/Mypage/BankConfig'));
const IdolStamp = lazy(() => import('pages/Mypage/IdolStamp'));
const Video = lazy(() => import('pages/Mypage/video'));
const Config = lazy(() => import('pages/Mypage/configs'));
const SendNoti = lazy(() => import('pages/Mypage/noti'));
const ListHistoryNoti = lazy(() => import('pages/Mypage/noti/TableHistroty'));
const TableInprogress = lazy(() => import('pages/Mypage/noti/TableInprogress'));
const MemberBlock = lazy(() => import('pages/Mypage/Member'));
const MemberFollow = lazy(() => import('pages/Mypage/MemberFollow'));
const MemberPremium = lazy(() => import('pages/Mypage/MemberPremium'));
const ProductList = lazy(() => import('pages/Mypage/Product/ProductList'));
const ProductAction = lazy(() => import('pages/Mypage/Product/ProductAction'));
const LuckyBagList = lazy(() => import('pages/Mypage/LuckyBag/LuckyBagList'));
const LuckyBagAction = lazy(() => import('pages/Mypage/LuckyBag/LuckyBagAction'));
const PostList = lazy(() => import('pages/Mypage/Post/List'));
const PostAction = lazy(() => import('pages/Mypage/Post/Action'));
const PostDetail = lazy(() => import('pages/Mypage/Post/Detail'));
const OrderList = lazy(() => import('pages/Mypage/Order/OrderList'));
const ListPackagesMonthly = lazy(() => import('pages/Mypage/PackagesMonthly/List'));
const PackagesMonthlyAction = lazy(() => import('pages/Mypage/PackagesMonthly/Action'));
const BlogList = lazy(() => import('pages/Mypage/Blog/List'));
const BlogAction = lazy(() => import('pages/Mypage/Blog/Action'));
const BlogDetail = lazy(() => import('pages/Mypage/Blog/Detail'));
const ListMemberPackage = lazy(() => import('pages/Mypage/PackagesMonthly/MemberPackage'));

export default function PageWrapper() {
  const isAuthenticated = !!Cookies.get('token');
  const { data: profile } = useQuery('profile', loadProfile, { enabled: isAuthenticated });
  const { data: currentLiveStream } = useQuery('currentLiveStream', getCurrentLiveStream, { enabled: isAuthenticated });
  const { data: listConfigs } = useQuery('getListConfigs', getListConfigs, { enabled: isAuthenticated });

  useEffect(() => {
    if (isAuthenticated) {
      authenticateSocket();
    }
  }, []);

  useEffect(() => {
    if (!!listConfigs && Array.isArray(listConfigs?.data)) {
      const isSetupPremium = listConfigs.data?.find((item: any) => item.key === IDOL_CONFIGS.PRICE_PREMIUM_IDOL);
      if (!isSetupPremium?.value) {
        alertPopup({
          title: '月額配信の月額金額を設定して下さい',
          onOk: () => {
            history.push('/configs');
          },
        });
      }
    }
  }, [listConfigs]);
  if (!isAuthenticated) return <Redirect to="/login" />;
  if (currentLiveStream === undefined) return null;
  if (!profile) return null;

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.mainWrapper}>
        <PageHeader />
        <div className={styles.pageContent} id="pageContent">
          <Suspense fallback={null}>
            <Switch>
              <Route path="/" component={Main} exact />
              <Route path="/mypage" component={Mypage} exact />
              <Route path="/mypage/detail/:memberId" component={DetailPrivateMailNew} exact />
              <Route path="/video-history" component={VideoHistory} exact />
              <Route path="/gallery" component={Gallery} exact />
              <Route path="/profile" component={Profile} exact />
              <Route path="/idol-revenue" component={IdolRevenue} exact />
              <Route path="/change-password" component={ChangePassword} exact />
              <Route path="/bank-config" component={BankConfig} exact />
              <Route path="/idol-stamp" component={IdolStamp} />
              <Route path="/video" component={Video} exact />
              <Route path="/configs" component={Config} exact />
              <Route path="/send-noti" component={SendNoti} />
              <Route path="/list-history-noti" component={ListHistoryNoti} />
              <Route path="/list-noti-inprogress" component={TableInprogress} />
              <Route path="/member-block" component={MemberBlock} exact />
              <Route path="/member-follow" component={MemberFollow} exact />
              <Route path="/member-premium" component={MemberPremium} exact />
              <Route path="/product-list" component={ProductList} exact />
              <Route path="/product-list/add" component={ProductAction} exact />
              <Route path="/product-list/:id/edit" component={ProductAction} exact />
              <Route path="/lucky-bags" component={LuckyBagList} exact />
              <Route path="/lucky-bags/add" component={LuckyBagAction} exact />
              <Route path="/lucky-bags/:id/edit" component={LuckyBagAction} exact />
              <Route path="/posts" component={PostList} exact />
              <Route path="/posts/add" component={PostAction} exact />
              <Route path="/posts/:id/edit" component={PostAction} exact />
              <Route path="/posts/:id" component={PostDetail} exact />
              <Route path="/packages-monthly-list" component={ListPackagesMonthly} exact />
              <Route path="/packages-monthly/add" component={PackagesMonthlyAction} exact />
              <Route path="/packages-monthly/:id/edit" component={PackagesMonthlyAction} exact />
              <Route path="/packages-monthly/list-member" component={ListMemberPackage} exact />
              <Route path="/blogs" component={BlogList} exact />
              <Route path="/blogs/add" component={BlogAction} exact />
              <Route path="/blogs/:id/edit" component={BlogAction} exact />
              <Route path="/blogs/:id" component={BlogDetail} exact />
              <Route path="/order-list" component={OrderList} exact />
            </Switch>
          </Suspense>
        </div>
        <Footer />
      </div>
    </div>
  );
}
