import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import _ from 'lodash';
import logo from 'assets/images/logo.png';
import { KEY_MENU_MOBILE } from 'common';
import useIsMobile from 'hooks/useIsMobile';
import { Drawer, Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import { getTotalUnreadPrivateMail } from 'api/privateMail';
import { useQuery } from 'react-query';

const routes: any = [
  {
    key: '1',
    text: '基本情報',
    url: '/profile',
  },
  {
    key: '2',
    text: 'ギャラリー',
    url: '/gallery',
  },
  {
    key: '3',
    text: '秘密のメール',
    url: '/mypage',
  },
  {
    key: '111',
    text: 'フォロワー',
    url: '/member-follow',
  },
  {
    key: '4',
    text: '獲得ポイント',
    url: '/idol-revenue',
  },
  {
    key: '5',
    text: '商品管理',
    url: '/product-list',
  },
  {
    key: '6',
    text: 'オーダー管理',
    url: '/order-list',
  },
  {
    key: '15',
    text: '月額会員申込管理',
    url: '/member-premium',
  },
  {
    key: '7',
    text: '福袋登録',
    url: '/lucky-bags',
  },
  {
    key: '8',
    text: '設定',
    children: [
      {
        key: '8.1',
        text: 'パスワード変更',
        url: '/change-password',
      },
      {
        key: '8.2',
        text: '配信動画',
        url: '/video',
      },

      {
        key: '8.3',
        text: '投げ銭設定',
        url: '/idol-stamp',
      },
      {
        key: '8.4',
        text: '項目設定',
        url: '/configs',
      },
      {
        key: '8.5',
        text: '銀行口座設定',
        url: '/bank-config',
      },
    ],
  },
  {
    key: '9',
    text: 'プッシュ配信',
    children: [
      {
        key: '9.1',
        text: 'プッシュ配信',
        url: '/send-noti',
      },
      {
        key: '9.2',
        text: 'プッシュ配信予約一覧',
        url: '/list-noti-inprogress',
      },
      {
        key: '9.3',
        text: 'プッシュ配信履歴',
        url: '/list-history-noti',
      },
    ],
  },
  {
    key: '10',
    text: '月額動画',
    children: [
      {
        key: '10.1',
        text: '月額一覧',
        url: '/packages-monthly-list',
      },
      {
        key: '10.2',
        text: '月額設定',
        url: '/packages-monthly/add',
      },
      {
        key: '10.3',
        text: '月額プラン会員申込一覧',
        url: '/packages-monthly/list-member',
      },
    ],
  },
  {
    key: '11',
    text: 'アーカイブ投稿管理',
    url: '/posts',
  },
  {
    key: '12',
    text: '日記投稿管理',
    url: '/blogs',
  },
  {
    key: '13',
    text: 'ユーザーブロック ',
    url: '/member-block',
  },

  {
    key: '14',
    text: 'ログアウト',
    url: '/logout',
  },
];
export default function MenuMobile() {
  const { isOpenMenuMobile, setIsOpenMenuMobile } = useIsMobile();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState('1');
  const [defaultOpenKeys, setDefaultOpenKeys] = useState<any>([]);

  const { data: totalUnread } = useQuery('total-unread', () => getTotalUnreadPrivateMail());

  useEffect(() => {
    let inMenu = false;
    routes.forEach((route: any) => {
      if (location.pathname.startsWith(route.url || '###')) {
        setSelectedKey(route.key);
        inMenu = true;
      }
      if (route.children) {
        route.children.forEach((childRoute: any) => {
          if (location.pathname.startsWith(childRoute.url || '###')) {
            setSelectedKey(childRoute.key);
            setDefaultOpenKeys([route.key]);
            inMenu = true;
          }
        });
      }
      if (!inMenu) setSelectedKey('');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const onOpenChange = (keys: any) => {
    // setIsOpenMenuMobile(false);
    setDefaultOpenKeys(keys);
  };

  const onSelectItem = (keys: any) => {
    setIsOpenMenuMobile(false);
  };

  return (
    <Drawer
      placement="left"
      visible={isOpenMenuMobile}
      onClose={() => setIsOpenMenuMobile(false)}
      headerStyle={{ display: 'none' }}
      className={styles.menuMobile}
    >
      <div className={styles.logoInMenu}>
        <img src={logo} alt="" className="img-scaledown" />
      </div>
      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={defaultOpenKeys}
        openKeys={defaultOpenKeys}
        onOpenChange={onOpenChange}
        onSelect={onSelectItem}
        className={styles.menuBody}
        mode="inline"
      >
        {routes.map((route: any) => {
          if (route.children) {
            return (
              <SubMenu key={route.key} icon={route.icon} title={route.text}>
                {route.children?.map((childRoute: any) => (
                  <Menu.Item key={childRoute.key}>
                    <Link to={childRoute.url}>{childRoute.text}</Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          }
          return (
            <Menu.Item key={route.key} icon={route.icon}>
              <Link to={route.url}>{route.text}</Link>{' '}
              {route.key === '3' && Number(totalUnread?.data?.total) > 0 && (
                <div className={styles.badge}>{Number(totalUnread?.data?.total)}</div>
              )}
            </Menu.Item>
          );
        })}
      </Menu>
    </Drawer>
  );
}
