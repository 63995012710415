import { useQuery, useQueryClient } from 'react-query';

import { beautyDefaultOptions } from 'common/default-value';
import { IBeautyOptionsData } from 'common/interface';
import agoraManager from 'helper/agoraManager';
import { QueryKey } from 'utils/constant/enum';
import useProfile from './useProfile';
import { transformBeautyOptionsData } from 'helper';

export default function useBeautyOptions() {
  const queryClient = useQueryClient();
  const profile = useProfile();

  const { data: beautyOptions } = useQuery<IBeautyOptionsData>([QueryKey.BEAUTY_OPTIONS], async () => {
    return queryClient.getQueryData([QueryKey.BEAUTY_OPTIONS]) || profile?.filterInfo || beautyDefaultOptions;
  });

  const handleEnableFilter = async () => {
    await agoraManager.getClient();
    await agoraManager.enableBeauty();
  };
  const handleDisableFilter = async () => {
    await agoraManager.disableBeauty();
  };
  const handleBeautyOptionsChange = async (options: IBeautyOptionsData) => {
    await agoraManager.changeProcessorOptions(
      transformBeautyOptionsData({
        ...beautyOptions,
        ...options,
      })
    );
    queryClient.setQueryData([QueryKey.BEAUTY_OPTIONS], { ...beautyOptions, ...options });
  };

  return { handleEnableFilter, handleDisableFilter, beautyOptions, handleBeautyOptionsChange };
}
