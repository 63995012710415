import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export default function useFreeTimer() {
  const queryClient = useQueryClient();
  const { data: showFreeTimer }: any = useQuery(
    'freeTimer',
    () => {
      return {
        visible: false,
        count: 0,
      };
    },
    { enabled: false }
  );
  const setShowFreeTimer = useCallback((show: any) => queryClient.setQueryData('freeTimer', show), [queryClient]);

  return { showFreeTimer, setShowFreeTimer };
}
