import React, { lazy } from 'react';
import { getAllResource } from 'api/resource';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';
import AuthWrapper from 'wrappers/AuthWrapper';
import SocketWarning from 'components/SocketWarning';

const Login = lazy(() => import('pages/Login'));
const Logout = lazy(() => import('pages/Logout'));
const SignUp = lazy(() => import('pages/SignUp'));
const Register = lazy(() => import('pages/Register'));

export default function AppWrapper() {
  const { data: resources } = useQuery('resources', () => getAllResource());
  if (!resources) return null;
  return (
    <div className="root-wrapper" id="root-wrapper">
      {/* <SocketWarning /> */}
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/register" exact component={Register} />
        <Route path="/" component={AuthWrapper} />
      </Switch>
    </div>
  );
}
