import { useCallback } from 'react';
import { useQuery, useQueryClient } from 'react-query';

export default function usePointRevenue() {
  const queryClient = useQueryClient();
  const { data: pointRevenue }: any = useQuery('pointRevenue', () => queryClient.getQueryData('pointRevenue'));

  const clearPointRevenue = useCallback(() => {
    queryClient.setQueryData('pointRevenue', undefined);
  }, [queryClient]);

  return { pointRevenue, clearPointRevenue };
}
