export enum STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum LiveStreamStatus {
  INACTIVE = 0,
  FREE = 1,
  CHARGE = 2,
  AUCTION = 3,
  SECRET_CHAT = 35,
  TWO_SHOT = 4,
  COMPLETED = 5,
  PREMIUM = 6,
}
export enum LiveStreamVideoType {
  NORMAL = 1,
  TWO_SHOT = 2,
  PREMIUM = 3,
}

export enum BloodType {
  O = 1,
  A = 2,
  B = 3,
  AB = 4,
}

export enum readStatus {
  UNREAD = 0,
  READ_NOT_REPLY = 1,
  REPLIED = 2,
}

export enum MessageSenderType {
  IDOL = 1,
  MEMBER = 2,
}

export enum MessageType {
  Text = 1,
  Stamp = 2,
  Image = 3,
  Product = 4,
  Premium = 5,
}
export enum ListLiveStreamVideoType {
  NORMAL = 1,
  TWO_SHOT = 2,
  PREMIUM = 3,
}

export const HIGH_GIFT_THRESHOLD = 3000;

export enum POINT_HISTORY_TYPE {
  BUY = 1,
  REFUND = 2,
  PARTY_CHAT = 3,
  PARTY_PEEP = 4,
  PRIVATE_MESSAGE = 5,
  PRIVATE_MAIL = 6,
  GIFT = 61,
  PRIVATE_REQUEST = 7,
  AUCTION = 8,
  BUY_VIDEO = 9,
  EXPIRE = 10,
  SUBSCRIPTION = 11,
  ORDER = 12,
  SUBSCRIPTION_MONTHLY = 15,
  LUCKY_BAG = 17,
  BONUS_CREDIT = 18,
  PREMIUM = 19,
}

export enum COMMON_STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum STAMP_TYPE {
  GIFT = 1,
  REQUEST = 2,
}

export enum LIMIT_STAMP {
  YES = 1,
  NO = 0,
}

export enum VIDEO_STATUS {
  DISABLE = 0,
  ENABLE = 1,
  STREAMING = 2,
  PROCESSING = 3, // Đang xử lý
  FAILED = 4, // Xử lý lỗi
}

export enum NotiIdolType {
  FOLLOW = 1,
  GIVE_STAMP_FOLLOW = 2,
  NOT_GIVE_STAMP_FOLLOW = 3,
  VIEWER_NOT_ONLINE_WEEK = 4,
  VIEWER_NOT_JOIN_WEEK = 5,
  ALL_VIEWR = 6,
  VIEWER_ON_NOTI = 7,
}

export const optionsNotification = [
  {
    type: NotiIdolType.FOLLOW,
    label: 'フォローしてくれた人',
  },
  {
    type: NotiIdolType.GIVE_STAMP_FOLLOW,
    label: 'フォローしてくれた人の中から、投げ銭を送ってくれた人',
  },
  {
    type: NotiIdolType.NOT_GIVE_STAMP_FOLLOW,
    label: 'フォローしてくれた人の中から、投げ銭をまだ送ってくれていない人',
  },
  {
    type: NotiIdolType.VIEWER_NOT_ONLINE_WEEK,
    label: '直近1週間以内にログインをしていない視聴者',
  },
  {
    type: NotiIdolType.VIEWER_NOT_JOIN_WEEK,
    label: '直近1週間以内に特定配信者のライブ配信を観ていない視聴者',
  },
  {
    type: NotiIdolType.ALL_VIEWR,
    label: 'ライブ配信を観てくれた視聴者',
  },
  {
    type: NotiIdolType.VIEWER_ON_NOTI,
    label: 'お知らせ通知をONにしている視聴者',
  },
];

export enum KEY_MENU_MOBILE {
  MY_PAGE = 1,
  MAIL_RECEIVED = 2,
  MAIL_SEND = 3,
  HOME_MESSAGE = 4,
  GUIDE_TOP = 5,
  GUIDE_POINT = 6,
  LIST_QA,
  CONTACT,
  ABOUT_US,
  DISPLAY_SPECIFIED,
  PROTECT_INFO,
  TERMS,
}
export enum TYPE_SELECTED {
  PROFILE_IDOL = 1,
  IMAGE = 2,
  VIDEO = 3,
  MESSAGE = 4,
  NOTE = 5,
}

export enum STATUS_MEMBER {
  BLOCK = 0,
  ACTIVE = 1,
  PENDING = 2,
  DELETE = 3,
}

export enum TYPE_CHAT {
  PUBLIC = 0,
  PRIVATE = 1,
}

export enum DebounceTime {
  DEFAULT = 500,
}

export enum IsSale {
  TRUE = 1,
  FALSE = 0,
}

export enum PathName {
  ADD_PRODUCT = '/product-list/add',
  ADD_PACKAGES_MONTHLY = '/packages-monthly/add',
}

export enum OrderStatus {
  PREPARING = 1,
  IN_TRANSIT = 2,
  SUCCESS = 3,
  FAILED = 4,
}

export enum FormatTime {
  DATE_TIME = 'DD/MM/YYYY HH:mm',
  DATE = 'DD/MM/YYYY',
  DATE_REVERSE = 'YYYY/MM/DD',
  DATE_JA = 'YYYY-MM-DD',
}

export enum LiveStreamMediaStatus {
  ALL_VIDEO_AUDIO = 1,
  ONLY_VIDEO = 2,
  ONLY_AUDIO = 3,
}

export enum IDOL_CONFIGS {
  VIEW_PER_MONTH_PRICE = 'VIEW_PER_MONTH_PRICE',
  OFF_COMMENT_LIVESTREAM = 'OFF_COMMENT_LIVESTREAM',
  PRICE_PREMIUM_IDOL = 'PRICE_PREMIUM_IDOL',
}

export enum MESSAGE_TYPE {
  MEMBER = 1,
  IDOL = 2,
}
export enum EventSocket {
  New_Private_Msg = 'new_private_msg',
}

export enum CommonSelectStatus {
  ALL = 1,
  MULTIPLE = 2,
}

export enum KeyboardKeyCode {
  FORWARD_SLASH = 191,
}
