export enum QueryKey {
  BANK_ACCOUNT_INFO = 'BANK_ACCOUNT_INFO',
  PRODUCT_LIST = 'product_list',
  PRODUCT_DETAIL = 'product_detail',
  ORDER_LIST = 'order_list',
  ORDER_DETAIL = 'order_detail',
  PRODUCT_COMMENT_LIST = 'product_comment_list',
  PACKAGES_MONTHLY_LIST = 'packages_monthly_list',
  BEAUTY_OPTIONS = 'beauty_options',
  LUCKY_BAG_LIST = 'lucky_bag_list',
  LUCKY_BAG_DETAIL = 'lucky_bag_detail',
  VIDEO_LIST = 'video_list',
  POST_LIST = 'post_list',
  POST_DETAIL = 'post_detail',
  BLOG_LIST = 'blog_list',
  BLOG_DETAIL = 'blog_detail',
}
export enum DeviceType {
  MOBILE = 1,
  DESKTOP = 2,
}
